import { Box, Link, Typography, Grid, IconButton, Menu, MenuItem, Card, CardMedia, List, CardContent, ListItem, ListItemIcon, ListItemText, TextField, Button, Divider } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../assets/images/logo.png'
import googleplay from '../../assets/images/googleplay.png'
import iosbtn from '../../assets/images/iosbtn.png'
import home4 from '../../assets/images/home4.jpg'
import { theme } from '../../theme'
import { Build, Business, CheckCircle, Home, List as ListIcon, People, Person } from '@mui/icons-material';
import { Footer } from '../Navigation';
import { Apis } from '../../helpers';

interface StateProps {
    numberOfUsers: number
    anchorEl: null | HTMLElement

}

const Landing: FC = () => {
    const [state, setState] = useState<StateProps>({
        anchorEl: null,
        numberOfUsers: 0
    })
    const { anchorEl, numberOfUsers } = state
    const links = [
        { label: 'Home', url: '#home' },
        { label: 'Services', url: '#services' },
        { label: 'Pricing', url: '#pricing' },
        { label: 'Contact', url: '#contact' },
        { label: 'Get Started', url: '/auth' }
    ];

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setState({ ...state, anchorEl: event.currentTarget as any });
    };
    const provisions = [
        {
            image: 'https://spottechs.nyc3.cdn.digitaloceanspaces.com/sheltergram/assets/images/homePaint.jpg',
            title: 'Home Services',
            description: 'Elevate your business by showcasing your expertise on our platform, connecting you with clients ready to experience your exceptional services.'
        },
        {
            image: 'https://spottechs.nyc3.cdn.digitaloceanspaces.com/sheltergram/assets/images/Screenshot%202024-08-21%20at%2021.49.26.png',
            title: 'Property Listing',
            description: 'Discover your dream property or list your own with ease on our comprehensive property listing platform.'
        },
        {
            image: 'https://spottechs.nyc3.cdn.digitaloceanspaces.com/sheltergram/assets/images/Screenshot%202024-08-21%20at%2021.49.41.png',
            title: 'Automatic Record Keeping',
            description: 'Simplify your property management with automated record-keeping, ensuring accuracy and efficiency every step of the way.'
        }
    ]
    const services = [
        {
            icon: <Home sx={{ fontSize: 40, color: 'primary.main' }} />,
            title: 'Property Management',
            description: 'Simplify property management with our all-in-one platform. Manage, and maintain effortlessly.'
        },
        {
            icon: <ListIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
            title: 'Property Listing',
            description: 'Easily list your properties and find your perfect match with our intuitive platform.'
        },
        {
            icon: <Build sx={{ fontSize: 40, color: 'primary.main' }} />,
            title: 'Service Listing',
            description: 'Showcase your expertise and connect with clients effortlessly through our service listing platform.'
        },
        {
            icon: <People sx={{ fontSize: 40, color: 'primary.main' }} />,
            title: 'Connect House Seekers with Landlords',
            description: 'Bridge the gap between house seekers and landlords seamlessly with our intuitive platform.'
        },
        {
            icon: <Business sx={{ fontSize: 40, color: 'primary.main' }} />,
            title: 'Connect Tenants to Service Providers',
            description: 'Facilitate seamless connections between tenants and service providers, ensuring their needs are met with ease.'
        }
    ];
    const basicFeatures = [
        'Listing properties',
        'Rent collection',
        'Record keeping',
        "Tenant Reminder Notifications",
        'Tenant Screening and Onboarding'
    ]
    const silverPlan = [
        'Debt recovery',
        'Maintenance',
        'Caretaking'
    ]
    const pricingPlans = [
        {
            title: 'Bronze Lite Plan',
            price: '2.5% of Rent Paid',
            description: 'Landlord manages the property themselves using the platform.',
            advantages: [
                'Rent collection',
                'Record keeping',
                "Tenant Reminder Notifications"
            ]
        },
        {
            title: 'Bronze Plan',
            price: '5% of Rent Paid',
            description: 'Landlord manages the property themselves using the platform.',
            advantages: [...basicFeatures, 'Payments are remitted immediately when the tenants pays.']
        },
        {
            title: 'Silver Plan',
            price: '7.5% of Rent Paid',
            description: 'Sheltergram handles the entire management.',
            advantages: [
                ...basicFeatures,
                ...silverPlan,
                'Payments are remitted immediately when the tenants pays.'
            ]
        },
        {
            title: 'Gold Plan',
            price: '10% of Rent Paid',
            description: 'Sheltergram handles the entire management.',
            advantages: [
                ...basicFeatures,
                ...silverPlan,
                'Total payments are made on 5th of every month regardles of whether tenants have paid or not.'
            ]
        }
    ];
    const getNumberOfUsers = () => {
        Apis({
            module: 'Users',
            action: 'getNumberOfUsers'
        }).then((numberOfUsers: any) => {
            setState({ ...state, numberOfUsers })
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleMenuClose = () => {
        setState({ ...state, anchorEl: null })
    };
    useEffect(() => {
        getNumberOfUsers()
        return () => {

        }
    }, [])


    return (
        <div
            style={{
                backgroundColor: '#F7F7F7',
                height: '100%'
            }}
            id={'home'}
        >
            <Box sx={{
                height: 100,
                display: 'flex',
                alignItems: 'center',
                p: 3.5,
                justifyContent: 'space-between'
            }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <img style={{
                        height: 70,
                        width: 70
                    }} src={logo} />
                    <Typography sx={{ fontSize: 30, color: theme.lightTheme.palette.primary.main }}>Sheltergram</Typography>
                </Box>

                {/* Links Section - Hidden on Small Screens */}
                <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                    {
                        links.map(({ label, url }) => {
                            let obj: any = {}
                            if (label === 'Get Started')
                                obj = {
                                    bgcolor: theme.lightTheme.palette.primary.main,
                                    color: '#fff',
                                    p: 1.5,
                                    borderRadius: 20,
                                    fontWeight: 'bold'
                                }
                            return (
                                <Link sx={{
                                    fontSize: 18,
                                    textTransform: 'uppercase',
                                    ml: 2,
                                    mr: 2,
                                    textDecoration: 'none',
                                    ...obj
                                }} key={label} href={url}>{label}</Link>
                            )
                        })
                    }
                </Box>
                <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
                        <MenuIcon color='primary' sx={{ fontSize: 30 }} />
                    </IconButton>
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    {links.map(({ label, url }) => (
                        <MenuItem sx={{ color: theme.lightTheme.palette.primary.main }} key={label} onClick={handleMenuClose} component="a" href={url}>
                            {label}
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
            <Grid container
                sx={{
                    bgcolor: '#fff',
                    height: 1000,
                    px: 3.5, // Use padding instead of margin
                    borderTopRightRadius: { xs: 0, md: 150 },
                    borderBottomLeftRadius: { xs: 0, md: 150 },
                    overflowX: 'hidden', // Prevent horizontal scroll
                }}
                spacing={0}
                alignItems="stretch"
            >
                <Grid item xs={12} md={6}
                    sx={{
                        display: 'flex',
                        overflow: 'hidden'
                    }}>
                    <img
                        src={home4}
                        style={{
                            borderBottomLeftRadius: 300,
                            objectFit: 'cover',
                            maxWidth: '100%',
                            height: '100%'
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        pt: { xs: 2, md: 4 },
                        px: { xs: 2, md: 10 },
                        color: theme.lightTheme.palette.primary.main
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: { xs: 28, md: 40 },
                            fontWeight: 'bold',
                            textAlign: 'center',
                            textShadow: '1.5px 1.5px 4px rgba(0, 0, 0, 0.5)'
                        }}
                    >
                        Where landlords and tenants meet hassle-free
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: { xs: 16, md: 20 },
                            mt: { xs: 1, md: 2 },
                            textAlign: 'center'
                        }}
                    >
                         Your all-in-one solution for landlords and tenants alike. With streamlined processes and seamless communication, we simplify rental management, making it easier for landlords to manage properties and for tenants to find their ideal home. Join us today and experience hassle-free property management like never before.
                    </Typography>
                    <Box sx={{ textAlign: 'center', mt: { xs: 4, md: 10 } }}>
                        <Typography variant="h3">Get Our Apps On</Typography>
                        <a href="https://play.google.com/store/apps/details?id=com.sheltergram.co" style={{ marginTop: '16px', display: 'block' }}>
                            <img
                                src={googleplay}
                                alt="Google Play Store"
                                style={{ width: 250, height: 70, borderRadius: 8 }}
                            />
                        </a>
                        <a href="https://apps.apple.com/us/app/sheltergram/id6698868923" style={{ marginTop: '16px', display: 'block' }}>
                            <img
                                src={iosbtn}
                                alt="Apple App Store"
                                style={{ width: 250, height: 70, borderRadius: 8 }}
                            />
                        </a>
                        <Box sx={{ marginTop: { xs: 5, md: 10 } }}>
                            <Typography sx={{ fontSize: 20 }} variant="body2" className="tagline-style2">
                                Join our community of {numberOfUsers} satisfied customers today!
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>



            <Grid container
                sx={{
                    p: 3.5,
                    pt: 10,
                    color: theme.lightTheme.palette.primary.main,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                <Typography sx={{
                    fontSize: 40,
                    fontWeight: 'bold',
                    textShadow: '1.5px 1.5px 4px rgba(0, 0, 0, 0.5)'
                }}>We handle everything</Typography><br />
                <Typography sx={{
                    maxWidth: { xs: '100%', sm: '100%', md: '50%' },
                    mt: 2,
                    fontSize: 20
                }}>Empowering landlords to effortlessly list their properties from anywhere, connecting seamlessly with house seekers, while streamlining record updates and providing a platform for service providers to showcase their expertise.</Typography>
            </Grid>
            <Box sx={{
                p: 3,
                backgroundColor: '#F7F7F7'
            }}>
                <Grid container spacing={4}>
                    {provisions.map((provision, index) => (
                        <Grid item xs={12} sm={12} md={4} key={index}>
                            <Card sx={{
                                boxShadow: '2px 2px 1px #EEEEEE'
                            }}>
                                <CardMedia
                                    component="img"
                                    image={provision.image}
                                    alt={provision.title}
                                    sx={{ height: 300, objectFit: 'contain' }}
                                />
                                <CardContent sx={{
                                    color: theme.lightTheme.palette.primary.main,
                                }}>
                                    <Typography
                                        sx={{
                                            fontSize: 30,
                                            textShadow: '1.5px 1.5px 2px rgba(0, 0, 0, 0.5)'
                                        }}
                                        variant="h6" gutterBottom>
                                        {provision.title}
                                    </Typography>
                                    <Typography
                                        sx={{ fontSize: 20 }}
                                        variant="body2" color="textSecondary">
                                        {provision.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Grid container
                id={'services'}
                sx={{
                    backgroundColor: '#F7F7F7',
                    p: 3.5,
                    pt: 10,
                    color: theme.lightTheme.palette.primary.main,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                <Typography sx={{
                    fontSize: 40,
                    fontWeight: 'bold',
                    textShadow: '1.5px 1.5px 4px rgba(0, 0, 0, 0.5)'
                }}>Our Services</Typography><br />
                <Typography sx={{
                    maxWidth: { xs: '100%', sm: '100%', md: '50%' },
                    mt: 2,
                    fontSize: 20
                }}>Our platform offers a comprehensive suite of services designed to streamline every aspect of property management.</Typography>
            </Grid>
            <Box sx={{ p: 3, bgcolor: '#fff' }}>
                <Grid container spacing={4}>
                    {services.map((service, index) => (
                        <Grid item xs={12} sm={12} md={4} key={index}>
                            <Box sx={{ p: 2 }}>
                                {service.icon}
                                <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
                                    {service.title}
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 1 }}>
                                    {service.description}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Grid container
                id={'pricing'}
                sx={{
                    backgroundColor: '#F7F7F7',
                    p: 3.5,
                    pt: 10,
                    color: theme.lightTheme.palette.primary.main,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                <Typography sx={{
                    fontSize: 40,
                    fontWeight: 'bold',
                    textShadow: '1.5px 1.5px 4px rgba(0, 0, 0, 0.5)'
                }}>Pricing</Typography>
            </Grid>
            <Box sx={{ p: 3 }}>
                <Grid container spacing={4}>
                    {pricingPlans.map((plan, index) => (
                        <Grid item xs={12} sm={12} md={6} key={index}>
                            <Box sx={{ p: 3, border: '1px solid #ddd', borderRadius: 2 }}>
                                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                                    {plan.title}
                                </Typography>
                                <Typography variant="h6" sx={{ color: 'primary.main', mb: 2 }}>
                                    {plan.price}
                                </Typography>
                                <Typography variant="body1" sx={{ mb: 3 }}>
                                    {plan.description}
                                </Typography>
                                <List>
                                    {plan.advantages.map((advantage, i) => (
                                        <ListItem key={i} sx={{ p: 0 }}>
                                            <ListItemIcon sx={{ minWidth: 30 }}>
                                                <CheckCircle sx={{ color: 'primary.main' }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography variant="body2">{advantage}</Typography>}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Grid container
                id={'contact'}
                sx={{
                    backgroundColor: '#F7F7F7',
                    p: 3.5,
                    pt: 10,
                    color: theme.lightTheme.palette.primary.main,
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center'
                }}>
                <Typography sx={{
                    fontSize: 40,
                    fontWeight: 'bold',
                    textShadow: '1.5px 1.5px 4px rgba(0, 0, 0, 0.5)'
                }}>Get In Touch With Us</Typography><br />
                <Typography sx={{
                    mt: 2,
                    fontSize: 20,
                    textAlign: 'center'
                }}>Our dedicated team is ready to assist you every step of the way. Connect With Us Today!</Typography>
            </Grid>
            <Box sx={{ p: 3, maxWidth: 600, mx: 'auto' }}>
                <form noValidate autoComplete="off">
                    <Grid container spacing={2}>
                        {/* Name and Email in one row */}
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                fullWidth
                                label="Name"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                fullWidth
                                label="Email"
                                variant="outlined"
                                type="email"
                            />
                        </Grid>
                        {/* Subject in its own row */}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Subject"
                                variant="outlined"
                            />
                        </Grid>
                        {/* Description as a larger TextField in its own row */}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Description"
                                variant="outlined"
                                multiline
                                rows={6}
                            />
                        </Grid>
                        {/* Submit Button */}
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Button sx={{ textTransform: 'none' }} variant="contained" color="primary">
                                Send Message
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                <Divider sx={{ my: 4 }} />
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        Hill Court, Nyeri Nyahururu Highway, Kenya
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        <Link href="tel:+254786481885" sx={{ textDecoration: 'underline' }}>
                            +254 786481885
                        </Link>
                    </Typography>
                    <Typography variant="body1">
                        <Link href="mailto:info@sheltergram.co" sx={{ textDecoration: 'underline' }}>
                            info@sheltergram.co
                        </Link>
                    </Typography>
                </Box>
            </Box>
            <Footer />

        </div >
    )
}

export default Landing
